<template>
  <div>
    <v-dialog
      v-model="isVisibleDialog"
      persistent
      max-width="700px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="$admin.can('terminals.create')"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="openModal"
        >
          {{ $t("add_new") }}
        </v-btn>
      </template>

      <terminals-form
        v-if="isVisibleDialog"
        type="create"
        :loading="isLoading"
        @onsave="saveItem"
        @onclose="closeForm"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    TerminalsForm: () => import("../utils/TerminalsForm.vue"),
  },
  props: {
    filterOptions: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isLoading: false,
      isVisibleDialog: false,
    };
  },

  computed: {
    ...mapGetters({
      authUser: "getUser",
    }),
  },

  watch: {
    // add any required watchers here
  },


  methods: {
    openModal() {
      this.isVisibleDialog = !this.isVisibleDialog;
    },
    closeForm() {
      // this.$refs.form.reset();
      this.isVisibleDialog = false;
    },
    async saveItem(payload) {
      this.isLoading = true;
      let params = { payload, filter: { ...this.filterOptions } };
      await this.$store
        .dispatch("terminal/addTerminal", params)
        .then(() => {
          this.$store.dispatch(
            "alerts/success",
            this.$t("created_successfully")
          );
          this.closeForm();
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>